html {
    background-color: #FFFFFF;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}
:root {
    --text-primary-color: #585b60;;    ;
    --link-primary-color: #ed5148;
    --title-color: #313841;
    --hover-color: #2081e2;
    --table-bg: #e7e7ec;
    --second-table-bg: #242930;
}
h1, h2, h3, h4, body {
    font-family: 'Roboto', sans-serif;
}

h1 {
    font-size: 39px;
    font-weight: bold;
    margin-bottom: 25px;
    text-transform: uppercase;
}

h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 62px;
    padding: 40px 0 25px;
    color: var(--title-color);
}
h2+p{
    margin: 0 0 20px!important;
}

 p {
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
    color: var(--text-primary-color);
    margin: 20px 0;
    z-index: 1;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: var(--link-primary-color);
    font-weight: 700;
}

/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    margin-bottom: 40px;
}
.banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
}
.banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
}
.banner .title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 60px;
    color: #090909;
}
.banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin: 0 0 0 15px;
    background-color: transparent;
}
.banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
}
.banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
}
.banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
    .banner .title{
        font-size: 22px;
        line-height: 50px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
}
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }


.grid {
    grid-gap: 20px;
    display: grid;
    gap: 20px;
}

.col1-2 {
    grid-template-columns: 1fr 2fr;
}
.col2 {
    grid-template-columns: 1fr 1fr;
}
.col3-2 {
    grid-template-columns: 3fr 2fr;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1149px;
}
.d-none {
    display: none;
}

.text-center {
    text-align: center;
}
.text-white{
    color: white;
}
.m-center {
    margin: 0 auto;
}
.m-visible {
    display: none;
}
.m-hidden {
    display: initial;
}
.bullets-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
}
.bullets2 {
    list-style: disc;
    padding-bottom: 15px;
    text-indent: 4px;
    margin-top: 10px;
}
::marker{
    color: rgb(165, 165, 165);
    font-size: 21px;
}
.bullets2 li {
    font-size: 16px;
    line-height: 36px;
    color: var(--text-primary-color);
    position: relative
}
.bullets-wrapper .bullets2:first-child {
    margin-right: 166px;
}
/* Header */
.scroll-to-link > *{
    pointer-events: none;
 }
.site-header {
    background-color: #12141a;
    padding: 0 0 50px;
}
.site-header::after{
    content: "";
    background-image: url(../image/bg-header.webp);
    background-position: 80% top;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 150px;
    right: 0;
    width: 100%;
    max-width: 800px;
    height: 600px;
}
.site-header .container{
    z-index: 1;
    position: relative;
}
.site-logo{
    margin-bottom: 40px;
}
.site-logo a, .footer .site-logo a{
    display: inline-block;
    font-size: 0;
    height: 48px;
    position: relative;
    vertical-align: middle;
    width: 250px;
    background: url(../image/logo.png) no-repeat 0 0/contain;
}
.intro-highlight{
    font-size: 82px;
    display: block;
}
.intro-primary{
    margin: 100px 0 60px;
}
.intro{
    font-size: 16px;
    line-height: 37px;
    color: #e3e3e3;
    margin-bottom: 80px;
}
.slide-down-measure p {
    margin: 0 !important;
    padding-top: 20px;
}
.slide-down {
    height: 0;
    overflow: hidden;
    transition: height 0.2s linear;
}
.read-more-wrapper {
    margin-top: 10px;
}
.read-more {
    line-height: 22px;
    padding-bottom: 5px;
    color: #cdced1;
    font-size: 14px;
}
.read-more:hover {
    cursor: pointer;
}
.cta-buttons{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}
.cta-buttons{
    position: relative;
}
.cta-buttons li{
    position: relative;
}
.cta-buttons li:nth-child(n+3)::after{
    border-left: 1px solid #484b53;
    bottom: 0;
    content: "";
    height: 60%;
    left: -4px;
    position: absolute;
    top: 10px;
    width: 1px;
}

.cta-buttons .btn, .btn{
    border-radius: 10px;
    display: flex;
    text-align: unset;
    background-color: transparent;
    padding: 15px 10px;
    color: #cdced1;
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    width: 100%;
    position: relative;
}
.cta-buttons li:not(:first-child) .btn{
    margin-left: 20px;
}

.cta-buttons .highlight.btn, .btn.secondary{
    background-color: var(--link-primary-color);
    color: #FFFFFF;
    text-indent: 0;
    font-weight: bold;
}
.cta-buttons .highlight.btn:hover, .btn.secondary:hover{
    background-color: var(--hover-color);
    color: white;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
}
.cta-buttons .btn:hover{
    color: var(--hover-color);
    -webkit-transition: color 250ms linear;
    -ms-transition: color 250ms linear;
    transition: color 250ms linear;
}
.cta-buttons .icon:before{
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    height: 30px;
    position: absolute;
    top: 10px;
    width: 20px;
    margin-left: -1.5em;
}
.cta-buttons .icon:hover::before {
    filter: invert(45%) sepia(50%) saturate(4058%) hue-rotate(193deg) brightness(93%) contrast(90%);
}
.cta-buttons .icon.first::before {
    background-image: url(../image/sell.png);
}
.cta-buttons .icon.second::before {
    background-image: url(../image/markets.png);
}
.cta-buttons .icon.third::before {
    background-image: url(../image/blockchain.png);
}
.cta-buttons .icon.four::before {
    background-image: url(../image/mobile.png);
}
.cta-buttons .icon.five::before {
    background-image: url(../image/faqs.png);
}
.btn.secondary{
    justify-content: center;
    font-weight: 700;
    text-transform: uppercase;
    padding: 10px 20px;
}
.btn.secondary.blue{
    width: 90%;
    margin: 0 auto;
    padding: 3px 0;
    background-color: white;
    border: 1px solid var(--link-primary-color);
    color: var(--link-primary-color);
    -webkit-transition: color 250ms linear, border-color 250ms ease; 
    -ms-transition: color 250ms linear, border-color 250ms ease; ;
    transition: color 250ms linear, border-color 250ms ease; ;
}
.btn.secondary.blue:hover{
    border: 1px solid var(--hover-color);
    color: var(--hover-color);
}
/* table */
.t-top-title {
   margin-top: 35px;
}

.t-top-tabs {
    background-color: var(--table-bg);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
    ul#horizontal-list {
        list-style: none;
        padding: 20px;
    }
    
    ul#horizontal-list li {
        display: table-cell;
        position: relative;
    }
    
    ul#horizontal-list li a {
        font-size: 16px;
        line-height: 35px;
        color: #717c8c;
        font-weight: 700;
        text-align: center;
        transition-duration: .2s;
        padding: 20px;
    }
    ul#horizontal-list li:hover .top-tab-a{
        color: #2081e2;
    }
    
    ul#horizontal-list li.active a {
        color: #363640;
    }
    
    ul#horizontal-list li:hover {
        cursor: pointer;
    }
    
    ul#horizontal-list li.active a::after {
        content: '';
        position: absolute;
        left: 43%;
        top: 85%;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid #35353f;
        clear: both;
        z-index: 2;
    }
    /* ul#horizontal-list li+li:before{
      content: '| ';
      color: #b0bac7;
    } */
    ul#horizontal-list li {
        border-right: 1px solid #b0bac7;
    }
    ul#horizontal-list li:last-of-type {
        border-right: none;
    }
    .add-text{
        color: #707a85;
        font-size: 14px;
        font-weight: 600;
        padding: 0 20px;
        position: relative;
    }
    .add-text:before {
        content: "";
        background: url(../image/trusted-icon.png) left no-repeat;           
        height: 30px;
        position: absolute;
        top: -6px;
        left: -6px;
        width: 20px;
    }
.t-section {
    background: var(--bg-primary-color);
    border-radius: 26px;
    margin: 30px 0;
}

.mytabs {
    display: none;
    height: 0;
    opacity: 0;
}

.mytabs >  div {
    overflow: hidden;
}

.mytabs.active {
    height: auto;
    opacity: 1;
    display: block;
    transition: opacity 0.3s linear;
}

.d-none {
    display: none;
}

table.home-table {
    width: 100%;
    border: 10px;
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: 20px;
    font-style: normal;
    font-size: 20px;
    line-height: 37px;
    color: #4e545c;
    font-size: 14px;
    padding: 0 20px;
    border-left-style: solid transperant;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right-style: solid transperant;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    background: rgb(231,231,235);
    background: linear-gradient(0deg, transparent 0%, rgba(244,244,246,0.4) 60%, rgba(231,231,235,1) 100%);
}

.home-table thead tr th{
    letter-spacing: 1px;
    color: #858d98;
    font-weight: 400;
    text-transform: uppercase;
    padding: 5px 0 15px;
}

.home-table tbody tr {
    padding: 5px;
    border-radius: 28px;
    position: relative;
}
.home-table tbody tr::after, .steps::before, .types .type::before, .faq::before{
    display: inline-block;
    content: "";
    border-top: 1px solid #cdd7e4;
    width: 97%;
    position: absolute;
    margin: 0 1rem;
    transform: translateY(-1rem);
    left: 0;
}

table.home-table td {
    border: solid 1px transparent;
    border-style: solid none;
    padding: 0 15px 10px;
    text-align: center;
    max-width: 250px;
    position: relative;
}

table.home-table thead tr:not(:first-of-type, :last-of-type, :nth-of-type(5))::after {
    content: '';
    height: 80%;
    width: 1px;
    position: absolute;
    right: 0;
    top: 10px;
}
.operator{
    padding-right: 45px!important;
}

table.home-table li{
    margin-left: 75px;
}
.t-logo{
    display: flex;
}
.t-logo .logo {
    content: '';
    max-width: 100%;
    width: 180px;
    height: 90px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
    font-size: 0px;
    border-radius: 25px;
    padding: 15px 0px;
}
.t-logo .small-logo {
    font-size: 20px;
    color: #494f57;
    font-weight: 700;
    position: relative;
}
.t-logo .small-logo::before{
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    float: left;
    font-size: 0;
    height: 35px;
    margin: 0 10px 0 0;
    width: 35px;
}
.logo-bihance{
    background: url(../image/behance-logo.webp);
}
.logo-binance-small::before{
    background: url(../image/binance-circle.png);
}
.logo-foundation{
    background: url(../image/foundation-logo.webp);
}
.logo-foundation-circle::before{
    background: url(../image/foundation-circle.png);
}
.logo-nbatopshop{
    background: url(../image/nbatopshop-logo.webp);
}
.logo-opensea::before{
    background: url(../image/opensea-circle.png);
}
.logo-enter::before{
    background: url(../image/enterart-circle.png);
}
.logo-showroom::before{
    background: url(../image/nftshowroom-circle.png);
}
.logo-topps::before{
    background: url(../image/topps-circle.png);
}
.logo-sportsfinda::before{
    background: url(../image/sportsfinda-circle.png);
}
.logo-crypto-small::before{
    background: url(../image/cryptocom-circle.png);
}
.logo-rarible-small::before{
    background: url(../image/rirable-circle.png);
}
.logo-opensea-large{
    background: url(../image/opensea-logo.webp);
}
.logo-veve{
    background: url(../image/veve.webp);
}

.t-os-icon{
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: auto;
    height: 30px;
    margin: 0 auto;
    width: 50px;
    display: inline-block;
    vertical-align: middle;
    font-size: 0;
}
.t-type{
    min-width: 70px;
    max-width: 130px;
    text-align: center;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 0px;
    background-position: center;
}
.t-type.trade{
    background-image: url(../image/trading-big-min.png);
}
.t-type.market{
    background-image: url(../image/marketplace-small-min.png);
}
.t-rating{
    font-weight: 700;
    font-size: 16px;
}
.t-rating small{
    font-weight: 300;
}
ul.list-highlights {
    text-align: left;
    list-style: none;
    font-weight: 500;
}

ul.list-highlights{
    font-size: 14px;
}
.first{
    font-size: 1.25em;
}
.list-highlights.first{
    font-size: 1.02em;
}

ul.list-highlights li::before {
    content: "✓";
    font-family: Poppins;
    color: #23dca5;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    padding-right: 22px;
}

/* Second table */
#mobile-table{
    padding-bottom: 50px;
}
#mobile-table .t-top-title{
    margin: 0;
    padding: 50px 0 30px;
}
.mob-table{
    margin-bottom: 50px;
}
.mob-table .t-top-tabs{
    background-color: var(--second-table-bg);
}
.mob-table ul#horizontal-list li.active a, .mob-table .t-logo .small-logo{
    color: white;
}
.mob-table ul#horizontal-list li a, .mob-table .add-text{
    color: #97a2b2;
}
.mob-table ul#horizontal-list li.active a::after{
    border-top-color: white;
}
.mob-table table.home-table{
    background: var(--second-table-bg);
    background: linear-gradient(0deg, transparent 0%, #24293061 60%, var(--second-table-bg) 100%);
    color:white;
}
.mob-table .home-table tbody tr::after{
    border-top-color: #353d46;
}
.mob-table .t-type{
    filter: brightness(0) invert(1);
}
.mob-table .btn.secondary.blue{
    background-color: transparent;
}
.bg-grey{
    background-color: #f1f1f4;
}
.bg-black{
    background-color: #1d1c24;
}
.block{
    padding: 20px 0;
} 
.block-img{
    width: 100%;
    max-width: 700px;
    display: block;
    margin: 0 auto;
    margin-bottom: 25px;
}
.steps{
    padding: 40px;
}
.steps::before{
    border-top: 1px solid #32303c;
    width: 80%;
    margin: 0 auto;
    right: 0;
}
.step-box{
    padding: 40px 0;
    text-align: left;
    min-height: 350px;
    align-items: center;
}
.step-box:nth-child(even){
    direction: rtl
}
.step-box::before{
    content: '';
    width: 100%;
    height: 100%;
    background-size: 350px;
    background-position: center center;
    background-repeat: no-repeat;
    float: left;
}
#steps-of-selling .step-box:nth-child(1)::before{
    background-image: url(../image/step-1.webp);
}
#steps-of-selling .step-box:nth-child(2)::before{
    background-image: url(../image/step-2.webp);
}
#steps-of-selling .step-box:nth-child(3)::before{
    background-image: url(../image/step-3.webp);
}
#steps-of-selling .step-box:nth-child(4)::before{
    background-image: url(../image/step-4.webp);
}


#type-of-blockchain .step-box:nth-child(1)::before{
    background-image: url(../image/eter.webp);
}
#type-of-blockchain .step-box:nth-child(2)::before{
    background-image: url(../image/bih.webp);
}
#type-of-blockchain .step-box:nth-child(3)::before{
    background-image: url(../image/pol.webp);
}
#type-of-blockchain .step-box:nth-child(4)::before{
    background-image: url(../image/tron.webp);
}
#type-of-blockchain .step-box:nth-child(5)::before{
    background-image: url(../image/sol.webp);
}
#type-of-blockchain .step-box:nth-child(6)::before{
    background-image: url(../image/aval.webp);
}
#type-of-blockchain .steps::before{
    border-top-color: #cdd7e4;
}

#type-of-blockchain .step-box h3::before {
    color: black;
    content: "\2022";
    display: inline-block;
    font-family: Poppins;
    font-size: 26px;
    font-weight: 700;
    width: 1em;
    position: absolute;
    margin-left: -25px;
    top: -4px;
}
#type-of-blockchain .step-box h3 {
    position: relative;
    direction: ltr;
    text-transform: uppercase;
    margin-left: 25px;
}
/* Types */
.types, .faq-wrapper{
    margin: 50px 0 0 0;
}
.types .type, .faq {
    padding: 30px 0;
    position: relative;
}
.types .type::before, .faq::before{
    top: 0;
    width: 100%;
    margin: 0;
}
.types .type h3{
    text-indent: 40px;
    position: relative;
    background-position: 0;
    background-repeat: no-repeat;
    background-size: contain;
}
.types .type#art h3 {
    background-image: url(../image/art.png);
}
.types .type#music h3 {
    background-image: url(../image/music.png);
}
.types .type#photography h3 {
    background-image: url(../image/photography.png);
}
.types .type#sport h3 {
    background-image: url(../image/sport.png);
}

/* FAQs */
.faq h3{
    position: relative;
    text-transform: uppercase;
    font-size: 22px;
    color: #2c2c2c;
}
/* Contacts */

.cnt-button{
    margin: 0 auto;
    display: table!important;
    padding-bottom: 30px;
}
.cnt-button .btn{
    width: fit-content;
    position: relative;
    padding: 20px 70px;
    font-size: 18px;
}

/* Footer */
.footer{
    display: flex;
    justify-content: space-between;
    color: var(--text-primary-color);
}
.footer .site-logo{
    margin-bottom: 0px;
}
.footer .site-logo a{
    height: 30px;
}
@media only screen and (min-width: 1300px) {
    .site-header::after{
        left: 40%;
    }
}
@media (max-width: 1500px){
    .container {
        padding: 0 15px;
    }
}

@media only screen and (max-width: 868px) {

    h1{
        font-size: 37px;
        text-align: center;
    }
    h2{
        font-size: 34px;
        line-height: 40px;
    }
    h3{
        font-size: 18px;
    }
    .container {
        padding-left: 20px!important;
        padding-right: 20px!important;
    }
    .col2, .col3-2{
        grid-template-columns: 1fr;
    }

    .m-slide-down {
        -moz-transition: height .5s;
        -ms-transition: height .5s;
        -o-transition: height .5s;
        -webkit-transition: height .5s;
        transition: height .5s;
        height: 0;
        overflow: hidden;
    }
    .site-header::after{
        background-image: url(../image/mob-arrows.webp);
        width: 100%;
        height: 100%;
        background-position: top center;
        background-size: 1200px;
    }
    .site-logo,  .read-more-wrapper, p{
        text-align: center;
    }
    .intro-highlight{
        font-size: 43px;
    }
    .steps::before {
        border-top: 1px solid #32303c;
        width: 80%;
        margin: 0 auto;
        right: 0;
    }
    .intro-primary{
        margin-bottom: 0;
    }
    .intro{
        text-align: center;
    }
    .intro-primary p{
        width: 100%;
    }
    .cta-buttons{
        display: grid;
        row-gap: 13px;
        gap: 10px;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas: 
          "button button"
          "how mobile"
          "block faq"
          "types types";
    }
     .item-a {
        grid-area: button;
      }
      .item-b {
        grid-area: how;
      }
      .item-c {
        grid-area: types;
      }
      .item-d {
        grid-area: block;
      }
      .item-e {
        grid-area: mobile;
      }
      .item-f {
        grid-area: faq;
      }
      .cta-buttons .highlight.btn{
         justify-content: center;
      }
      .cta-buttons .btn, .btn{
          font-size: 15px;
      }
      .cta-buttons .icon:before{
        margin-left: -2em;
    }
    .cta-buttons li:nth-child(n+3)::after{
        content: none;
    }
    .t-top-title{
        margin-top: 0;
    }
    .bullets-wrapper {
        display: grid;
    }
    .bullets-wrapper .bullets2:first-child {
        margin-right: 0;
        padding-bottom: 10px;
    }
    .bullets2{
        padding-bottom: 0;
        margin-bottom: 0;
        margin-top: 0;
    }
    
    .step-box, .steps{
        padding: 20px 0;
        text-align: center;
    }
    .step-box:nth-child(even){
        direction: initial;
    }
    .step-box::before{
        width: auto;
        max-width: 100%;
        height: 300px;
        background-size: contain;
    }
    .faq-wrapper{
        margin: 0;
    }
    .types .type h3, .faq h3{
        margin: 0 auto;
        display: block;
        width: fit-content;
        text-align: center;
    }
    .types .type#photography h3{
        background-size: 30px;
    }
    .home-table tbody tr::after, .types .type::before, .faq::before{
        content: none;
    }
    .footer {
        display: grid;
        justify-content: center;
        text-align: center;
        padding: 15px 0;
        gap: 15px;
    }

    /* Table */
    .t-section {
        margin-top: 16px;
        margin-bottom: 0px;
        padding: 0;
    }
    ul#horizontal-list{
        display: table;
        justify-content: space-around;
        padding: 30px 0;
        width: 100%;
    }
    ul#horizontal-list li a{
        padding: 0;
        font-size: 16px;
        line-height:initial;
        display: block;
        align-items: center;
    }
    ul#horizontal-list li+li:before{
        width: 0;
        height: 100%;
        position: absolute;
        border: 1px solid #b0bac7;
        top: 0;
        left: 0;
    }
    ul#horizontal-list li.active a::after{
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 7px solid #35353f;
        top: 100%;
    }
    ul.list-highlights{
        font-size: 16px;
        line-height: 33px;
        font-weight: 400;
    }
    ul.list-highlights li::before{
       padding: 0;
    }
    .home-table tr {
        display: table;
        border-radius: 1px!important;
        max-width: -webkit-fill-available;
        width: 100%;
        padding: 10px 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid rgb(208, 208, 208);
    }
    .home-table tr:last-of-type{
        border-bottom: none;
    }
    .home-table td:nth-of-type(4){
        top: 15px!important;
    }
    
    .home-table thead tr {
        display: none;
    }

    .add-text{
        display: none;
    }
    .home-table tbody tr::after{
        margin: 30px 0 0;
    }
    .home-table td {
        display: inline-table;
        border: none !important;
        padding: 0;
        position: relative;
    }

    #table-1 .home-table td:nth-of-type(5) {
        width: 100%;
        max-width: 450px;
        border: none;
        display: block;
        margin: 0px auto;
        border-radius: 50px;
        padding-top: 6px;
    }

    #table-1 .home-table td:nth-of-type(1){
        width: 60%;
        padding: 0 0 0 4%;
    }

    #table-1 .home-table td:nth-of-type(2){
        display: none;
    }

    #table-1 .home-table td:nth-of-type(3) {
        width: 100%;
        padding-left: 4%;
        max-width: 100%;
    }

    #table-1 .home-table td:nth-of-type(4){
        position: absolute;
        top: 0px;
        right: 0;
    }

    .home-table li {
        margin-left: 10px!important;
    }

    .home-table .t-logo {
        width: 100%;
        background-size: 90%;
    }
    .t-logo .logo{
        padding: 0;
        height: auto;
        background-size: inherit;
    }
    .home-table .t-site-link {
        width: 90%;
        margin: 0 auto;
        box-shadow: none;
    }

    /* Table 2 */
    #mobile-table .t-top-tabs{
        justify-content: space-evenly;
    }
    #mobile-table .home-table td{
        width: 100%;
        max-width: 100%;
        text-align: right;
    }
    #mobile-table ul#horizontal-list li+li:before{
        right: 100px;
        left: initial;
        border: 1px solid #3c414a;
    }
    #mobile-table tr:not(:last-child) {
        border-bottom: 1px solid #333a44;
    }
  

    #mobile-table .home-table td:not(:first-child):not(:last-child){
        padding-left: 50%; 
    }
    #mobile-table .home-table td::before {
        color: #5c626c;
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        left: 0;
        position: absolute;
        text-transform: uppercase;
        width: 45%;
        white-space: nowrap;
        padding-right: 10px; 
    }
    #mobile-table .home-table td:nth-of-type(1):before { content: ""; }
    #mobile-table .home-table td:nth-of-type(2):before { content: "Type"; }
    #mobile-table .home-table td:nth-of-type(3):before { content: "Size (MB)"; }
    #mobile-table .home-table td:nth-of-type(4):before { content: "Requires"; }
    #mobile-table .home-table td:nth-of-type(5):before { content: "Current Version"; }
    #mobile-table .home-table td:nth-of-type(6):before { content: "Fee"; }

    #mobile-table .t-logo{
        justify-content: center;
    }
}
@media only screen and (max-width: 390px) {
    .item-c {
        justify-self: center;
    }
    #table-1 .home-table td:nth-of-type(4){
        position: absolute;
        top: 0px;
        right: 0;
    }
}
@media only screen and (max-width: 290px) {
    table.home-table{
        padding: 0px;
    }
    ul#horizontal-list li a{
        font-size: 14px;
    }
    .t-logo .logo{
        width: 100%;
        height: auto;
    }
    #table-1 .home-table td:nth-of-type(4){
        top: 0px!important
    }
    .t-logo .small-logo{
        font-size: 16px;
    }
    table.home-table li {
        margin-left: 10px;
    }
}


/* Read more */

.m-slide-down {
    height: 0;
    overflow: hidden;
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
}
.m-hidden, .mobile-detector {
    display: none;
}
.m-visible {
    display: initial;
}
.m-hidden {
    display: none;
}
.m-slide-down-measure p {
    margin: 0!important;
    padding-top: 20px;
}
.m-readmore-btn{
    background: transparent;
    border: none;
    font-size: 16px;
    color: white;
    width: 100%;
    padding-top: 20px;
    cursor: pointer;
}

.m-readmore-btn::before {
    cursor: pointer;
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: top;
    margin-left: 5px;
    color: white;
    font-size: 18px!important
}

.m-readmore-btn.read-more-2::before {
    content: "...";
}
.m-readmore-btn.read-more-2:hover::before, .m-readmore-btn.collapse-2:hover::before{
    color: var(--button-primary-color)
}
.m-readmore-btn * {
    pointer-events: none;
}
